<template>
  <div class="data-source-dic">
    <a-spin :spinning="spinning">
      <section>
        <a-row :gutter="8">
          <a-col :span="8" class="organization-unit-tree-panel" style="width: 40%;overflow-x: auto;">
            <group-panel
              @selectedNode="selectedNodeFunc"
            ></group-panel>
          </a-col>
          <a-col :span="16" class="organization-unit-tree-panel" style="width: 60%;">
            <a-row class="form">
              <!-- 暂无数据 -->
              <div
                class="no-data"
                v-if="!selectedTree.hasOwnProperty('items')"
              >
                <p>{{ l("请选择一个通用数据字典字段组以浏览其成员") }}</p>
              </div>
              <a-tabs
                defaultActiveKey="1"
                v-if="selectedTree.hasOwnProperty('items') && selectedTree.items.length"
              >
                <!-- 用户 -->
                <a-tab-pane :tab="selectedTree.title" key="1">
                  <panel :tree-data="treeData"></panel>
                </a-tab-pane>
              </a-tabs>
            </a-row>
          </a-col>
        </a-row>
      </section>
    </a-spin>
  </div>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import GroupPanel from './group-panel'
import Panel from './panel'


export default {
  mixins: [AppComponentBase],
  components: {
    GroupPanel,
    Panel,
  },
  data() {
    return {
      spinning: false,
      commonService: null,
      selectedTree: {},
      treeData: [],
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    selectedNodeFunc(data) {
      this.selectedTree = data;
    },
  },
}
</script>

<style lang="less" scoped>
.data-source-dic{

}
.organization-container{
  min-height: 600px;
}
.organization-unit-tree-panel {
  background-color: #fff;
  border: 1px solid #e8e8e8;
}
.units-header {
  height: 50px;
  border-bottom: 1px solid #e8e8e8;
  > .title {
    line-height: 50px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    margin-left: 20px;
  }
  p {
    line-height: 50px;
    &.left {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      margin-left: 20px;
    }
    a {
      margin-left: 10px;
    }
  }
}
/deep/.ant-tabs-bar {
  margin: 0 20px;
}
// 暂无数据
.no-data {
  border: 1px solid #e8e8e8;
  margin: 20px;
  p {
    text-align: center;
    margin-bottom: 0;
    line-height: 120px;
  }
}
</style>