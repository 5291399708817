<template>
    <a-spin @click="clearMenu" :spinning="spinning">
      <div class="wrapper" id="wrapper">
        <div class="header">
          <a-row align="middle" type="flex">
            <a-col :span="12">
                <p class="left">
                    <a-icon type="share-alt" />
                    {{ l('数据库字典') }}
                </p>
            </a-col>
            <a-col :span="12" v-if="isGranted('Pages.DataSourceDic.Query')" >
              <a-input-search
                name="keyword"
                :placeholder="l('请输入关键字')"
                @search="search"
                enterButton
                v-model.trim="keyword"
                allowClear
                style="width: 90%;position: relative;top: -8px;"
                />
            </a-col>
        </a-row>
        </div>
         
        <a-row class="tree" id="tree">
          <div class="t-w">
            <a-tree :treeData="list" showIcon defaultExpandAll @select="onSelect">
              <a-icon type="folder" slot="folder" />
              <a-icon type="file" slot="file" />
              <a-icon type="folder-open" slot="folder-open" />
              <template slot="custom" slot-scope="{ expanded }">
                  <a-icon :type="expanded ? 'folder-open' : 'folder'" />
              </template>
            </a-tree>
          </div>
            
        </a-row>
      </div>
       
    </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { OrganizationUnitServiceProxy } from "@/shared/service-proxies";
import { CommonServiceProxy } from "@/shared/common-service";
import { ModalHelper } from "@/shared/helpers";
import Bus from "@/shared/bus/bus";

export default {
    name: "organization-unit-tree-panel",
    mixins: [AppComponentBase],
    data() {
        return {
            spinning: false,
            _organizationUnitServiceProxy: null,
            commonService: null,
            _ouData: [],
            treeData: [],
            NodeTreeItem: null, // 右键菜单
            tmpStyle: "",
            // 选中的item
            activedNode: {},
            originList: [],
            list: [],
            keyword: '',
        };
    },
    created() {
        this._organizationUnitServiceProxy = new OrganizationUnitServiceProxy(
            this.$apiUrl,
            this.$api
        );
      this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
        this.$nextTick(() => {
            Bus.$on("reloadOrganizationUnitTree", data => {
                if (data) {
                    this._getEntitySummaryList();
                }
            });
        });
        if (this.isGranted('Pages.DataSourceDic.Query')) {
          this._getEntitySummaryList()
        }
    },
    mounted() {
      let w = this.$el.querySelector("#wrapper");
      let t = this.$el.querySelector("#tree");

      w.style.height = window.innerHeight - 138 - 10 + "px";
      t.style.height = window.innerHeight - 138 - 10 - 50 + "px";

    },
    beforeDestroy() {
        Bus.$off("reloadOrganizationUnitTree");
    },
    methods: {
      async _getEntitySummaryList() {
        this.spinning = true
        try {
          let res = await this.commonService.get({
            url: '/api/services/app/HostSettings/GetEntitySummaryList',
            params: {},
          })
          let arr = await this.initTreeData(res)
          this.originList = arr
          this.list = arr
          this.spinning = false
        } catch (error) {
          this.spinning = false
        }
      },
      search() {
        if (!this.keyword) {
          this.list = this.originList
        } else {
          this.list = this.originList.filter(v => v.title.indexOf(this.keyword) !== -1)
        }
        this.$emit("selectedNode", {});

      },
      initTreeData(res) {
          let arr = res.map(item => {
            return {
              key: item.key,
              title: item.name,
              children: [],
              items: item.items,
            }
          })
          return arr
        },
        /**
         * 重组Tree数据
         */
        treeDataMap() {
            const ouDtataParentIsNull = _.filter(
                this._ouData,
                item => item.parentId === null
            );
            return ouDtataParentIsNull.map(item =>
                this._recursionGenerateTree(item)
            );
        },
        /**
         * 选中item
         */
        onSelect(selectedKeys, info) {
          // console.log(selectedKeys)
          // console.log(info.node.title)

          let activedNode = this.originList.find(
              item => item.title == info.node.title
          )
          if (this.activedNode === activedNode) {
            this.activedNode = {}
          } else {
            this.activedNode = activedNode
          }
          this.$emit("selectedNode", this.activedNode);
          this.$nextTick(() => {
              Bus.$emit("selectedNode", this.activedNode);
          });
          // console.log("selected", this.activedNode);
        },
        /**
         * 用于点击空白处隐藏增删改菜单
         */
        clearMenu() {
            this.NodeTreeItem = null;
        },
    }
};
</script>

<style scoped lang="less">
.wrapper{
  position: relative;
  width: 100%;
  min-height: 400px;
  overflow: hidden;
}
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
    height: 50px;
    border-bottom: 1px solid #e8e8e8;
    p {
        line-height: 50px;
        &.left {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 500;
            margin-left: 20px;
        }
        a {
            margin-left: 10px;
        }
    }
}
.tree {
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    // height: 350px;
    overflow: scroll;
    
  .t-w{
  }
}
</style>
