<template>
  <a-spin :spinning="spinning">
    <react-table
      ref="listTable"
      :table-data="data"
      :total-items="data.length"
      :columns="columns"
      :showTotal="showTotalFun"
      :hide-row-selection="true"
      :hide-pagination="true"
      :scroll="{x: 0}"
    >
    </react-table>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { OrganizationUnitServiceProxy } from "@/shared/service-proxies";
import { ModalHelper } from "@/shared/helpers";
import Bus from "@/shared/bus/bus";
import { CommonServiceProxy } from "@/shared/common-service";
import ReactTable from "@/components/react-table";

export default {
  name: "general-fields-panel",
  mixins: [AppComponentBase],
  components: {
    ReactTable,
  },
  data() {
    return {
      spinning: false,
      commonService: null,
      generalFieldsService: null,
      selectedRowKeys: [],
      showName: "",
      key: "",
      filterText: "",
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 30, skipCount: 0 },
      // 用户表格
      columns: [
        {
          title: "键值",
          dataIndex: "key",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "key" },
        },
        {
          title: "名称",
          dataIndex: "name",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "name" },
        },
        
      ],
      // 用户数据
      data: [],
      selectTree: {},
      actionsType: {},
    };
  },
  computed: {},
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api)
    // 接受树结构传过来的选中item
    Bus.$on("selectedNode", this.getTree);
    // // 添加用户成功
    // Bus.$on("saveAddMemberSuccess", data => {
    //     if (data) {
    //         this.clearFilterAndRefresh();
    //     }
    // });
  },
  beforeDestroy() {
    Bus.$off("selectedNode");
  },
  methods: {
    /**
     * 选中树结构
     */
    getTree(data) {
      this.selectTree = data;
      console.log('getTree', data)
      this.data = data.items
      // console.log(this.selectTree);
      // this.getData();
      // this.treeData = data;
      // this.data = this.treeData
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
  },
};
</script>

<style scoped lang="less">
.user-container {
  border: 1px solid #e8e8e8;
  margin: 20px;
  padding: 20px;
}
.opeattion-container {
  margin: 20px 0;
  margin-bottom: 0;
  // padding: 0 20px;
}
.pagination {
  margin: 10px auto;
  text-align: right;
}
</style>
